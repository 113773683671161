@use '@iq/react-components/dist/colors.scss' as colors;

.pill-component {
  background: colors.$UIBlue;
  color: colors.$Grey0;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.4rem 0.2rem 0.2rem;

  &.clickable {
    cursor: pointer;
  }

  .avt-react-components--icon {
    height: 1rem;
    width: 1rem;
  }

  .text {
    font-size: 0.75rem;
    margin-left: 0.25rem;
  }
}
