@use '@iq/react-components/dist/colors.scss' as colors;

.site-detail-view-component {
  padding-left: 4rem;
}

.no-side-margin {
  padding-left: 0 !important;
}

.base-view {
  padding-left: 4rem;
  padding-top: 1px;

  .loading-container {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .panel-display-menu {
    display: flex;
    overflow: hidden;
  }

  .panel-display-menu a {
    float: left;
    display: block;
    text-align: center;
    padding: 1.5rem 2rem;
    text-decoration: none;
    font-size: medium;
    border-bottom: 3px solid transparent;
  }

  .mims-config {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1.5rem;
    gap: 10px;
  }

  .mims-config span {
    display: flex;
    width: 100%;
  }

  .mims-config dt {
    width: 180px;
  }
}

button {
  font-size: 0.5rem;
}
