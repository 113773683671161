.dropdown-menu--trigger {
  border-radius: 0.25rem;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  flex-grow: 1;

  &.slim {
    padding: 0.1rem;
    font-size: 0.8rem;
  }

  .dropdown-menu--trigger-content-left {
    display: flex;
    align-items: center;

    .dropdown-menu--trigger-label {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .pill-component {
      height: 0.8rem;
      border-radius: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.2rem;
      margin-left: 0.5rem;

      .text {
        margin: 0;
        padding: 0 0.2rem;
      }
    }
  }

  .avt-react-components--icon {
    margin-left: 0.5rem;
  }
}
