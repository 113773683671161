.data-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 2rem;
  margin-top: 2rem;

  .column-wrapper {
    width: 33%;
    position: relative;
    float: left;
  }

  .column {
    padding: 20px 10px 10px 10px;
    margin-right: 10px;
    overflow: auto;
    border: 0.5px solid #ccc;
    height: 60vh;
  }

  .title {
    position: absolute;
    top: -14px;
    left: 5px;
    padding: 5px;
    background: #fff;
  }
}

.dark-theme .data-container .title {
  color: #fff;
  background: #262626;
}
