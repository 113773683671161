@use '@iq/react-components/dist/colors.scss' as colors;

.bound-error {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > div {
    text-align: center;
  }

  .muted {
    color: colors.$Grey60;
    font-size: 80%;
    margin-top: 0.2rem;
  }

  button {
    margin: 1rem auto 0;
  }
}
