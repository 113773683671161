@use '@iq/react-components/dist/colors.scss' as colors;

.notifications-panel {
  width: calc((100vw - 2rem) / 3);
  min-width: 32.5rem;
  position: absolute;
  padding: 2rem;
  background-color: colors.$Grey85;
  top: 100%;
  margin-left: 0 !important;
  box-sizing: border-box;
  right: 0.8rem;
  border-radius: 0.2rem;
  border-style: solid;
  border-width: 1px;
}

.notifications-panel--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  .avt-react-components--icon.xs {
    svg {
      height: 12px;
      width: 12px;
    }
  }
}

.notifications-panel--body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 5rem;
  max-height: calc(100vh - 11rem);
  overflow: auto;

  .notification .notification-wrapper {
    width: auto;
  }

  .notifications-panel--body-expanded {
    padding: 0.5rem;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 0.5rem;

    .collapser {
      padding-bottom: 0.5rem;
      display: flex;
      justify-content: flex-end;
    }
  }

  .notifications-panel--body-empty {
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.dark-theme {
  .notifications-panel {
    background-color: colors.$Grey85;
    border-color: colors.$Grey80;
    box-shadow: 0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.35);
  }

  .notifications-panel--body-expanded {
    border-color: colors.$Grey80;
  }
}

.light-theme {
  .notifications-panel {
    background-color: colors.$Grey0;
    border-color: colors.$Grey10;
    box-shadow: 0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.15);
  }

  .notifications-panel--body-expanded {
    border-color: colors.$Grey10;
  }
}
