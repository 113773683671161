@use '@iq/react-components/dist/colors.scss' as colors;

.theme-swtich-component {
  .avt-react-components--button.design-text.activity-primary {
    &.theme-dark,
    &.theme-light {
      color: colors.$Grey50;
    }
  }

  .avt-react-components--icon.m {
    margin-left: 1rem;
  }
}
