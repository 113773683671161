@use '@iq/react-components/dist/colors.scss' as colors;
@use '../../../../scss/_variables.scss' as vars;

.header-settings-component {
  position: relative;
  left: -4px;
  top: 3px;

  .toggle-container {
    padding: 0.55rem 0;
    cursor: pointer;
    transform: rotate(90deg);
  }

  .content {
    max-width: 20rem;
    z-index: 10;
    box-sizing: border-box;
    position: absolute;
    display: none;
    white-space: nowrap;
    background: white;
    right: -5px;
    top: calc(100% + 1rem);
    border-radius: 0.25rem;
    border: 1px solid;

    .title {
      padding: 0.75rem;
      padding-bottom: 0.5rem;
      font-weight: 500;
    }

    .choices {
      padding: 1rem 0;

      .choice,
      .sub-choice {
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        color: colors.$Grey50;
        cursor: pointer;

        .avt-react-components--icon {
          margin-right: 10px;
        }
      }

      .sub-choice {
        font-weight: initial;
        padding: 0.5rem 2rem 0.5rem 4.25rem;

        &:last-child {
          margin-bottom: 1rem;
        }
      }
    }

    .sub-choices {
      border-bottom: 1px solid colors.$Grey20;
    }
  }

  .avt-react-components--icon {
    color: colors.$Grey50 !important;
  }

  &.open {
    .content {
      display: block;
    }
  }

  .isPolling {
    animation: spinner--spin 1000ms infinite linear;
  }
}

.light-theme .header-settings-component {
  .toggle-container {
    color: colors.$LightApplicationPrimaryText;
  }

  .content {
    background-color: colors.$LightApplicationPanelBackground;
    box-shadow: vars.$BoxShadowLight;
    border-color: colors.$Grey10;

    .title {
      color: colors.$LightApplicationSecondaryText;
    }

    .choice,
    .sub-choice {
      &:hover,
      &.selected {
        background: colors.$Grey10;
      }
    }

    .sub-choices {
      border-color: colors.$Grey20;
    }
  }
}

.dark-theme .header-settings-component {
  .toggle-container {
    color: colors.$DarkApplicationPrimaryText;
  }

  .content {
    background-color: colors.$DarkApplicationPanelBackground;
    box-shadow: vars.$BoxShadowDark;
    border-color: colors.$Grey80;

    .title {
      color: colors.$DarkApplicationSecondaryText;
    }

    .choice,
    .sub-choice {
      &:hover,
      &.selected {
        background: colors.$Grey80;
      }
    }

    .sub-choices {
      border-color: colors.$Grey90;
    }
  }
}
