.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0;

  > div:first-child {
    display: flex;
    align-items: center;

    > *:not(:first-child) {
      margin-left: 1rem;
    }

    .type-label-component {
      margin-right: 1rem;
      min-width: 8.2rem;
    }
  }

  &.title {
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  &.size-large div:first-child div:last-child {
    font-size: 1.4rem;
  }

  > div:last-child {
    display: flex;
    align-items: center;

    > * {
      margin-left: 1rem;
    }
  }
}
