@use '@iq/react-components/dist/colors.scss' as colors;

.icon-picker-toggler:focus {
  outline: none;
  box-shadow: 0 0 0 1px colors.$StatusBlue;
  border-color: colors.$StatusBlue;
}

.icon-picker-popup {
  padding: 10px;
  margin: 10px 0;
  max-height: 500px;
  overflow-y: auto;
  border-radius: 0.25rem;

  .icon-picker-icons {
    display: flex;
    flex-wrap: wrap;

    > button {
      margin-top: 5px;
      margin-right: 5px;
    }
  }
}

.dark-theme {
  .icon-picker-popup {
    background: colors.$DarkApplicationPanelBackground;
  }
}

.light-theme {
  .icon-picker-popup {
    background: colors.$LightApplicationPanelBackground;
    border: 1px solid colors.$LightApplicationBorder;
  }
}
