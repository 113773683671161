@use '@iq/react-components/dist/colors.scss' as colors;

.permissions-header {
  display: flex;
  justify-content: space-between;
  background: colors.$Grey0;
  padding: 1rem 1rem 0 1rem;
  border-radius: 0.5rem;

  > div:nth-child(1) {
    width: 12rem;
  }
}

.permission-checkbox-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;

  .permission-checkbox-checked {
    color: colors.$StatusBlue;
  }
}

.permission-label {
  width: 12rem;
  text-transform: capitalize;
}
