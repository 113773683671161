@use '@iq/react-components/dist/colors.scss' as colors;

.top-notification {
  position: fixed;
  z-index: 1000000;
  top: 1rem;
  left: 50vw;
  transform: translateX(-50%);
  width: 28rem;
  padding: 1rem;
  border-radius: 0.2rem;
  border-width: 1px;
  border-style: solid;
  height: fit-content;
  height: -moz-fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:first-child {
    min-height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .avt-react-components--icon {
      margin-right: 1rem;
    }
  }

  > div:only-child {
    width: 100%;
  }

  .avt-react-components--button {
    margin-left: auto;
  }
}

.dark-theme {
  .top-notification {
    border-color: colors.$DarkApplicationSecondaryText;
    background-color: colors.$Grey90;

    > div:first-child {
      color: colors.$DarkApplicationSecondaryText;
    }
  }
}

.light-theme {
  .top-notification {
    border-color: colors.$LightApplicationSecondaryText;
    background-color: colors.$Grey0;

    > div:first-child {
      color: colors.$LightApplicationSecondaryText;
    }
  }
}
