#global-message-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000; // should be highest index

  > div {
    &:empty {
      display:none
    }
  }
}
