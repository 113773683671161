@use '@iq/react-components/dist/colors.scss' as colors;

.site-create-view-component {
  display: flex;

  .avt-react-components--icon {
    margin-left: auto;
    opacity: 0.5;
  }

  &:hover .avt-react-components--icon {
    opacity: 0.75;
  }

  .loader-component {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.component-import-step {
  display: flex;
  flex-direction: column;
  height: 90%;
  margin-top: 2rem;
  background: white;

  &__button-row {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 1rem;
  }

  &__info-trigger {
    cursor: help;
    display: flex;
    align-items: center;
    font-weight: 500;

    .avt-react-components--icon.m {
      height: 1.4rem;
      width: 1.4rem;
      margin-right: 0.4rem;
    }
  }

  &__info-tooltip {
    display: none;
    background: white;
    width: 30rem;
    border: 1px solid colors.$Grey20;
    border-radius: 0.3rem;
    padding: 1rem;
    top: 0;
    right: 7rem;
    line-height: 1.2rem;

    p:not(:last-child) {
      padding-bottom: 0.5rem;
    }
  }

  &__drop-zone {
    flex-grow: 1;
    border: 2px dashed colors.$DarkApplicationSecondaryText;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem;
    cursor: pointer;

    > div {
      display: flex;
      align-items: center;

      color: colors.$DarkApplicationSecondaryText;
      justify-content: center;

      .avt-react-components--icon {
        margin-right: 0.5rem;
        flex-shrink: 0;
      }

      p {
        font-size: 1.25rem;
      }
    }
  }
}

.icon-green {
  color: colors.$StatusGreen;
}

.setup-wizard-component {
  &--basic .component-import-step {
    .icon-wiz-color {
      color: colors.$StatusBlue;
    }

    &__info-trigger {
      .avt-react-components--icon.m {
        color: colors.$WizardYellow !important;
      }

      &:hover + div {
        display: block;
        position: absolute;
      }
    }
  }

  &--advanced .component-import-step {
    .icon-wiz-color {
      color: colors.$WizardYellow;
    }

    &__info-trigger {
      .avt-react-components--icon.m {
        color: colors.$StatusBlue !important;
      }

      &:hover + div {
        display: block;
        position: absolute;
      }
    }
  }
}

.setup-wizard-errors.confirmation-dialog {
  .subtitle {
    h3 {
      text-transform: none;
    }
  }
}

.light-theme {
  .site-create-view-component {
    .loader-component {
      background: rgba(colors.$LightApplicationBackground, 0.6);
    }
  }

  .component-import-step {
    &__info-trigger {
      color: colors.$LightApplicationSecondaryText;
    }
  }
}

.dark-theme {
  .site-create-view-component {
    .loader-component {
      background: rgba(colors.$DarkApplicationBackground, 0.6);
    }
  }

  .component-import-step {
    &__info-tooltip {
      background: colors.$Grey85;
      border-color: colors.$Grey80;
    }

    &__info-trigger {
      color: colors.$DarkApplicationSecondaryText;
    }
    &__drop-zone {
      background-color: rgba(colors.$DarkApplicationBackground, 0.6);
    }
    p {
      color: colors.$Grey20;
    }
  }
}

.form-group.field input:read-only {
  background-color: #c7c7c7;
}
