@use 'sass:color';
@use '@iq/react-components/dist/colors.scss' as colors;

html {
  font-size: 15px !important;
  height: 100%;
  scroll-behavior: smooth;

  @media screen and (max-width: 1200px) {
    font-size: 14px !important;
  }
}

body {
  font-size: 15px !important;
  font-size: 100%;
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  overscroll-behavior-y: none;

  @media screen and (max-width: 1200px) {
    font-size: 14px !important;
  }
}

button,
input,
textarea {
  font-family: 'Open Sans', sans-serif;
}

body.light-theme {
  background-color: colors.$LightApplicationBackground;
  color: colors.$LightApplicationPrimaryText;
}

body.dark-theme {
  background-color: color.adjust(colors.$Grey95, $lightness: 2%);
  color: colors.$DarkApplicationPrimaryText;
}

#app {
  min-height: 100%;
}

a {
  text-decoration: none;
  color: colors.$UIBlue;
}

.heading {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 1rem 0 2rem;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .icon-component {
    width: 3rem;
    height: 3rem;
  }
}

button,
.avt-react-components--button,
.avt-react-components--button-group,
.grid .grid-item {
  -webkit-user-select: none !important;
}

button::-moz-focus-inner {
  border: 0;
}

a {
  cursor: pointer;
}

.custom-scrollbar {
  scrollbar-width: 7px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
  }

  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
}

.custom-thin-scrollbar {
  scrollbar-width: 4px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
}

.json-display-component .rjsf input[type='checkbox']:checked::after {
  top: 11%;
  left: 33%;
  width: 35%;
  height: 65%;
}

body.light-theme .custom-scrollbar,
body.light-theme .custom-thin-scrollbar {
  scrollbar-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0);

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

body.dark-theme .custom-scrollbar,
body.dark-theme .custom-thin-scrollbar {
  scrollbar-color: rgba(255, 255, 255, 0.25) rgba(0, 0, 0, 0);

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.25);
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  }
}

.avt-react-components--icon {
  &.xs {
    width: 12px !important;
    height: 12px !important;
  }
  &.s {
    width: 16px !important;
    height: 16px !important;
  }
  &.m {
    width: 24px !important;
    height: 24px !important;
  }
  &.l {
    width: 32px !important;
    height: 32px !important;
  }
  svg {
    width: 100%;
    height: 100%;
  }
}
