@use '@iq/react-components/dist/colors.scss' as colors;

.refresh-variables-button {
  background-color: transparent !important;
  .polling {
    animation: spinner--spin 1000ms infinite linear;
  }

  &.Lumada {
    height: 50px;
    border-radius: 50%;
    padding: 8px !important;
    color: #000000 !important;
    opacity: 0.5;
    &:hover {
      background: rgba(96, 107, 125, 0.04) !important;
    }
  }
}

@keyframes spinner--spin {
  0% {
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.dark-theme .refresh-variables-button {
  background: colors.$DarkApplicationPanelBackground;
  color: colors.$DarkApplicationSecondaryText;
}
