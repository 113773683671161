@use '@iq/react-components/dist/colors.scss' as colors;
@use '../../../scss/variables' as vars;

.user-avatar-button {
  height: 50px;
  border-radius: 50%;
  padding: 0 !important;
  background-color: transparent !important;
  color: colors.$LightApplicationPrimaryText;
  opacity: 0.5;
}

.user-info-panel {
  width: 350px;
  position: absolute;
  background-color: colors.$Grey0;
  top: 100%;
  margin-left: 0 !important;
  box-sizing: border-box;
  right: 0.8rem;
  border-radius: 0.3rem;
  border-style: solid;
  border-width: 1px;
  padding: 2rem;

  .user-info-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .user-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      row-gap: 10px;

      .user-avatar {
        display: flex;
        justify-content: center;

        .user-avatar-button {
          height: 50px;
          border-radius: 50%;
          padding: 8px !important;
          background-color: transparent !important;
          color: colors.$LightApplicationPrimaryText;
          opacity: 0.5;

          &:hover {
            background: rgba(96, 107, 125, 0.04) !important;
          }
        }
      }

      .user-name {
        font-size: 1.5rem;
      }

      .user-email {
        font-size: 1rem;
        padding-bottom: 30px;
      }
    }
  }

  .user-info-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .logout-button {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      height: 40px;
      cursor: pointer;
      gap: 0.5rem;
      &:hover {
        background-color: rgba(23, 25, 26, 0.05);
      }

      svg {
        color: colors.$Grey50;
        margin-right: 10px;
      }

      span {
        font-size: 1rem;
      }
    }
    .theme-switch {
      button {
        gap: 0.5rem;
      }
    }
  }
}

.dark-theme {
  .user-info-panel {
    background-color: colors.$Grey85;
    border-color: colors.$Grey80;
    box-shadow: vars.$BoxShadowDark;
  }

  .user-info-panel--body-expanded {
    border-color: colors.$Grey80;
  }

  .user-avatar-button {
    color: colors.$DarkApplicationPrimaryText !important;

    div {
      border-color: colors.$DarkApplicationBorder;
    }
  }
}

.light-theme {
  .user-info-panel {
    background-color: colors.$Grey0;
    border-color: colors.$Grey10;
    box-shadow: vars.$BoxShadowLight;
  }

  .user-info-panel--body-expanded {
    border-color: colors.$Grey10;
  }
}
