@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/OpenSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/OpenSans-Bold.ttf") format("truetype");
}
