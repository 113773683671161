.loader-component {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  padding: 1rem 0;

  .loader {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 2rem;
  }

  .loader-text {
    display: inline-block;
    margin-top: 1rem;
    text-align: center;
  }

  &.overlay {
    position: absolute;
    left: 0;
    right: 0;
    height: calc(100vh - 70px);
    width: 98vw;
    z-index: 998;
    padding: 0;
  }
}
