@use '@iq/react-components/dist/colors.scss' as colors;

.confirmation-dialog {
  .title {
    h2 {
      margin-bottom: 0.3rem;
    }
  }

  .subtitle {
    h3 {
      text-transform: uppercase;
    }
  }

  .body {
    padding: 2rem 0;

    p {
      padding-bottom: 0.5rem;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    button:first-child {
      margin-right: 1rem;
    }
  }
}

.dark-theme {
  .confirmation-dialog {
    background-color: colors.$Grey85;

    .subtitle {
      color: colors.$DarkApplicationSecondaryText;
    }
  }
}

.light-theme {
  .confirmation-dialog {
    background-color: colors.$Grey0;

    .subtitle {
      color: colors.$LightApplicationSecondaryText;
    }
  }
}
