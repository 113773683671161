@use '@iq/react-components/dist/colors.scss' as colors;

.input-title {
  size: 120%;
  text-transform: capitalize;
}

.input-tooltip-top-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}

.tooltip-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 200;

  .avt-react-components--icon {
    margin-left: 0.5rem;
  }
}

.input-tooltip-toggle {
  cursor: pointer;
}

.toggle-active,
.input-tooltip-description {
  color: colors.$StatusBlue;
}

.toggle-inactive {
  color: colors.$Grey50;
}

.input-tooltip-toggle {
  display: flex;
  align-items: center;

  .toggle-text {
    margin-left: 1rem;
    width: 4rem;
  }
}
