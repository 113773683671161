@use '@iq/react-components/dist/colors.scss' as colors;

#global-modal-container {
  .simple-modal-container {
    overflow: auto;
    position: fixed;
    z-index: 2000; // must be same or greater than PageHeader (.page-header-component)
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
  }

  .simple-modal-overlay {
    padding: 2rem 2rem;
    position: absolute;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;

    .simple-modal-wrapper {
      box-sizing: border-box;
      border-radius: 0.25rem;
      min-width: 20rem;
      margin: 0 auto;
      cursor: default;

      &.size-s {
        max-width: 40rem;
      }

      &.size-m {
        max-width: 60rem;
      }

      &.size-l {
        max-width: 120rem;
      }

      .simple-modal-header {
        padding: 1.5rem;
        border-bottom: 1px solid transparent;

        .simple-modal-main-header {
          display: flex;
          align-items: center;

          &__left {
            display: flex;
            align-items: center;

            .header-left + .title {
              margin-left: 0.5rem;
            }

            .title {
              font-weight: 500;
              font-size: 1.1rem;
            }
          }

          &__right {
            display: flex;
            align-items: center;
            margin-left: auto;

            .header-right + .close {
              margin-left: 0.5rem;
            }

            .he-close {
              cursor: pointer;
            }
          }
        }
      }

      .simple-modal-sub-header {
        padding-top: 1rem;
      }

      .simple-modal-body {
        padding: 1.5rem;
        position: relative;

        .date-time-picker {
          .content {
            width: 100%;
            flex-direction: row;
            padding-top: 15px;
          }
          .actions {
            position: absolute;
            bottom: 25%;
            right: 40%;
          }
          .absolute {
            width: 60%;
          }
          .relative {
            width: 40%;
          }
        }

        &.padding-none {
          padding: 0 !important;
        }
      }

      .simple-modal-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
      }
    }
  }

  &:empty {
    display: none;
  }
}

body.no-scroll {
  position: fixed;
  overflow: hidden;
  width: 100%;
}

.light-theme {
  .simple-modal-wrapper {
    background: colors.$LightApplicationBackground;

    .simple-modal-header {
      border-color: colors.$LightApplicationBorder !important;
    }
  }
}

.dark-theme {
  .simple-modal-wrapper {
    background: colors.$DarkApplicationPanelBackground;

    .simple-modal-header {
      border-color: colors.$DarkApplicationBorder !important;
    }
  }
}
