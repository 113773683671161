.loading-main-content {
  opacity: 0.3;
}

.main-content {
  opacity: 1;
  height: 100%;
}

.no-access,
.page-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
