@use '@iq/react-components/dist/colors.scss' as colors;

.json-display-component {
  padding-bottom: 1rem;

  .json-properties {
    position: relative;

    .prop-table {
      display: table;
      border-collapse: collapse;
      width: 100%;
    }

    .form-data-item {
      &:not(:last-child) {
        border-bottom: 1px solid;
      }
    }

    .row {
      display: table-row;

      .key,
      .value {
        display: table-cell;
        padding: 0.5rem;
        word-break: break-word;
        line-height: 150%;
      }

      .key {
        width: 40%;
        max-width: 30rem;
      }
    }
  }
  .rjsf {
    .form-group {
      margin-bottom: 1rem;
    }

    label {
      display: inline-block;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }
    .field-description {
      opacity: 0.65;
      margin-bottom: 1rem;
    }

    .btn-group {
      .btn {
        max-width: 30%;
      }
    }

    button.btn {
      box-sizing: border-box;
      border-radius: 3px;
      box-shadow: none;
      border-width: 1px;
      padding: 0px;
      background: colors.$UIBlue60;
      border: none;
      color: #fff;

      &.btn-add,
      &.array-item-remove {
        font-size: 1.7rem;
        width: 5rem;
      }
    }

    .text-danger {
      color: colors.$StatusRed;
    }

    input[type='checkbox'] {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: 0;
      height: 1rem;
      width: 1rem;
      border-radius: 0.2rem;
      margin: 0 0.5rem 0 0;
      position: relative;
      top: 0.2rem;

      &:hover {
        filter: brightness(90%);
      }

      &:disabled {
        opacity: 0.6;
        pointer-events: none;
      }

      &:after {
        content: '';
        position: relative;
        left: 40%;
        top: 20%;
        width: 15%;
        height: 40%;
        border: solid colors.$DarkApplicationPrimaryText;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        display: none;
      }

      &:checked:after {
        display: block;
      }
    }
  }

  .error--container {
    display: flex;
    background-color: rgba(255, 115, 0);
    border-radius: 0.2rem;
    padding: 0.6rem;
    color: colors.$Grey0;
    margin-bottom: 2rem;
    align-items: stretch;

    .error--icon {
      margin: 0.5rem;
    }

    .error--message {
      font-size: 15px;
      font-family: 'Open Sans', Arial, Helvetica, sans-serif;
      font-weight: 400;
      line-height: 26px;
    }
  }
}

.resize-none > textarea {
  resize: none;
}

.dark-theme {
  .json-display-component {
    .rjsf {
      input[type='checkbox'] {
        background: colors.$DarkApplicationPanelBackground;
        border: 1px solid colors.$DarkApplicationInputBorder;

        &:checked {
          background: colors.$StatusBlue;
        }

        &:disabled {
          background: colors.$Grey80;
        }
      }
    }
  }
}

.light-theme {
  .json-display-component {
    .rjsf {
      input[type='checkbox'] {
        background: colors.$LightApplicationPanelBackground;
        border: 1px solid colors.$LightApplicationInputBorder;

        &:checked {
          background: colors.$StatusBlue;
        }

        &:disabled {
          background: colors.$Grey5;
        }
      }
    }
  }
}
