.foldable-tree-view {

    .tree {
        list-style: none;
        margin: 0;
        padding: 20px;
    }

    .tree-node,
    .tree-node-group {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .tree-branch-wrapper,
    .tree-node__leaf {
        outline: none;
    }

    .tree-node--focused .name {
        background-color: gray;
        font-weight: 900;
        color: #fff;
    }

    .tree-node__branch {
        display: block;

        .name {
            padding: 2px;
        }
    }

    .tree-node {
        cursor: pointer;
        outline: none;
        padding: 2px 0;
    }

    .tree-leaf-list-item .tree-node {
        cursor: default;
    }

    .tree-node__leaf {
        margin-left: 25px;
    }

    .tree-node .avt-react-components--icon {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 16px;

        .arrow {
            position: absolute;
            margin-top: 2px;
        }
    }

    .name {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

}