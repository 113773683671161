@use '@iq/react-components/dist/colors.scss' as colors;
@use '@iq/react-components/dist/index.scss' as index;

.site-detail-view-component {
  padding-left: 4rem;
}

.no-side-margin {
  padding-left: 0 !important;
}

.base-view {
  padding-left: 4rem;
  padding-top: 1px;

  .panel {
    padding-top: 4.5rem;
    flex-direction: column;
    background-color: white;
    margin-right: 1rem;
    margin-left: 15rem;
  }

  .panel-header {
    display: flex;
    background-color: #f2f2f2;
    padding: 1.5rem;
    padding-right: 0;
    justify-content: space-between;
    flex-direction: row;
  }

  .panel-header-button {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    font-size: small;
  }

  .loading-container {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.content {
  display: none;
  padding: 1rem 1.5rem;
}

.content.active {
  display: block;
}

.preview-text {
  padding: 1.5rem;
}

.preview-text span {
  margin-top: 1rem;
}

.dark-theme {
  .panel {
    background: colors.$Grey85;
    color: colors.$DarkApplicationPrimaryText;

    .panel-header {
      background: colors.$Grey85;
      color: colors.$DarkApplicationPrimaryText;
    }

    .sidebar {
      background: colors.$Grey85;
      color: colors.$DarkApplicationPrimaryText;

      .site-list {
        .site {
          color: colors.$DarkApplicationTertiaryText;
          border-color: colors.$DarkApplicationBorder;

          .thumbnail {
            border-color: colors.$DarkApplicationBorder;
          }

          .name {
            color: colors.$DarkApplicationPrimaryText;
          }

          span {
            color: colors.$DarkApplicationPrimaryText;
          }
        }
      }
    }
  }
}
